export function getRoute(name, params = null) {
  // Instruction
  // name: method (get/post/patch) _ business/creator _ api_name
  // -----------------------------------------------------------
  const urls = [
    {
      name: "login",
      url: "auth/token/",
    },
    {
      name: "get_user_info",
      url: "auth/current/",
    },
    {
      name: "get_influencer_info",
      url: "accounts/creator/current/detail/",
    },
    {
      name: "update_influencer_info",
      url: "accounts/creator/:id/update/",
    },
    {
      name: "connect_fb_creator",
      url: "/social/:service/token/",
    },
    {
      name: "get_social_account",
      url: "/social/:service/account/",
    },
    {
      name: "get_social_accounts",
      url: "/social/:service/account/list/",
    },
    {
      name: "set_social_accounts",
      url: "/social/:service/account/connect/",
    },
    {
      name: "create_campaign",
      url: "business/campaign/create/",
    },
    {
      name: "get_campaign",
      url: "business/campaign/:id/detail/",
    },
    {
      name: "update_campaign",
      url: "/business/campaign/:id/update/",
    },
    {
      name: "create_brief_image",
      url: "/business/campaign/image/add/",
    },
    {
      name: "delete_brief_image",
      url: "/business/campaign/image/:id/remove/",
    },
    {
      name: "update_brief",
      url: "/business/campaign/:id/brief/update/",
    },
    {
      name: "brief_delete_deadline",
      url: "/business/campaign/brief/deliverable/:id/remove/",
    },
    {
      name: "brief_add_deadline",
      url: "/business/campaign/brief/deliverable/add/",
    },
    {
      name: "brief_update_deadline",
      url: "/business/campaign/brief/deliverable/:id/update/",
    },
    {
      name: "get_campaign_list",
      url: "/campaigns/business/campaign/list/",
    },
    {
      name: "business_get_campaign_lists",
      url: "/content/business/campaign/list/?limit=:limit&offset=:offset",
    },
    {
      name: "sign_up",
      url: "/auth/register/",
    },
    {
      name: "confirm",
      url: "/auth/confirm/",
    },
    {
      name: "connect_influencer_to_campaign",
      url: "/creator/public/connect/",
    },
    {
      name: "get_creator_campaign_list",
      url: "/content/creator/campaign/list/?limit=:limit&offset=:offset",
    },
    {
      name: "get_business_list",
      url: "/accounts/business/list/",
    },
    {
      name: "get_creator_campaing_info",
      url: "/content/creator/campaign/:id/detail/",
    },
    {
      name: "make_contract_creator",
      url: "/content/business/creator/:id/offer/",
    },
    {
      name: "select_business",
      url: "/accounts/business/:id/select/",
    },
    {
      name: "business_get_creators_in_campaign",
      url: "/content/business/campaign/:id/creator/list/?limit=:limit&offset=:offset",
    },
    {
      name: "business_get_delivery_in_campaigns",
      url: "/content/business/campaign/:id/delivery/list/?limit=:limit&offset=:offset",
    },
    {
      name: "creator_get_delivery_list",
      url: "content/creator/campaign/delivery/list/?limit=:limit&offset=:offset",
    },
    {
      name: "business_get_delivery_detail",
      url: "content/business/:id/detail/",
    },
    {
      name: "creator_get_delivery_detail",
      url: "content/creator/:id/detail/",
    },
    {
      name: "creator_update_delivery",
      url: "content/creator/:id/update/",
    },
    {
      name: "creator_send_to_chat",
      url: "content/creator/activity/create/",
    },
    {
      name: "business_update_delivery",
      url: "content/business/:id/update/",
    },
    {
      name: "business_send_to_chat",
      url: "/content/business/activity/create/",
    },
    {
      name: "get_brief",
      url: "/creator/public/:id/detail/",
    },
    {
      name: "business_get_campaign_info",
      url: "/business/campaign/:id/detail/",
    },
    {
      name: "create_new_business",
      url: "/accounts/business/create/",
    },
    {
      name: "get_business_info",
      url: "/accounts/business/:id/detail/",
    },
    {
      name: "update_business_info",
      url: "/accounts/business/:id/update/",
    },
    {
      name: "post_business_billing_info",
      url: "/accounts/business/billing/create/",
    },
    {
      name: "update_business_billing_info",
      url: "/accounts/business/billing/:id/update/",
    },
    {
      name: "get_business_feedback_list",
      url: "/content/business/campaign/:id/creator/feedback/list/?limit=:limit&offset=:offset",
    },
    {
      name: "post_business_feedback",
      url: "/content/business/campaign/creator/feedback/create/",
    },
    {
      name: "get_business_results_general_block",
      url: "/content/business/campaign/:id/results/general/",
    },
    {
      name: "get_business_results_creators_list",
      url: "/content/business/campaign/:id/results/creator/list/?limit=:limit&offset=:offset",
    },
    {
      name: "get_business_results_delivery_list",
      url: "/content/business/campaign/:id/results/delivery/list/?limit=:limit&offset=:offset",
    },
    {
      name: "get_creator_posts_instagram",
      url: "/social/:service/account/media/list/",
    },
    {
      name: "put_change_user_password",
      url: "/auth/current/password/update/",
    },
    {
      name: "get_business_instagram_video_old",
      url: "/social/:service/account/:influencer_id/media/:media_id/details/",
    },
    {
      name: "get_published_instagram_local_raw",
      url: "/content/business/:id/detail/",
    },
    {
      name: "get_business_activity_campaign",
      url: "/business/campaign/:campaign_id/activity/list/?user=:creator_id&limit=:limit&offset=:offset",
    },
    {
      name: "set_viewed_business_activity_campaign",
      url: "/business/campaign/:campaign_id/activity/viewed/",
    },
    {
      name: "post_business_activity_campaign",
      url: "/business/campaign/activity/create/",
    },
    {
      name: "get_creator_activity_campaign",
      url: "/creator/campaign/:campaign_id/activity/list/?limit=:limit&offset=:offset",
    },
    {
      name: "set_viewed_creator_activity_campaign",
      url: "/creator/campaign/:campaign_id/activity/viewed/",
    },
    {
      name: "post_creator_activity_campaign",
      url: "/creator/campaign/activity/create/",
    },
    {
      name: "get_creator_proposal_data",
      url: "content/business/creator/:id/detail/",
    },
    {
      name: "post_business_set_status_not_interested_for_creator",
      url: "content/business/creator/:id/not_interested/",
    },
    {
      name: "export_to_ccv",
      url: "content/business/campaign/:id/csv/report/",
    },
    {
      name: "post_business_download_content",
      url: "content/business/campaign/:id/published/content/request/",
    },
    {
      name: "get_business_download_content_token",
      url: "/content/business/campaign/published/content/:request_id/token/",
    },
    {
      name: "get_business_download_content_link",
      url: "content/business/campaign/published/content/:token/",
    },
    {
      name: "create_sourcing",
      url: "/accounts/business/sourcing/create/",
    },
    {
      name: "get_business_sourcing_list",
      url: "/accounts/business/sourcing/list/?limit=:limit&offset=:offset",
    },
    {
      name: "put_business_campaign_status_update",
      url: "business/campaign/:id/status/update/",
    },
    {
      name: "post_forgot_password_get_link",
      url: "auth/forgot/password/",
    },
    {
      name: "post_forgot_password_make_auth",
      url: "/auth/forgot/password/confirm/",
    },
    {
      name: "delivery_creator_get_chat",
      url: "/content/creator/:content_id/activity/list/?limit=:limit&offset=:offset",
    },
    {
      name: "delivery_business_get_chat",
      url: "/content/business/:content_id/activity/list/",
    },
    {
      name: "deliverable_set_deadline_date",
      url: "/content/business/:id/deadline/",
    },
    {
      name: "get_control_channels",
      url: "/control/reports",
    },
    {
      name: "get_control_ig_accounts",
      url: "/control/:channel_id",
    },
    {
      name: "get_control_report_stats",
      url: "/control/reports/:channel_id?from=:from&to=:to",
    },
    {
      name: "get_control_report_content",
      url: "/control/reports/:channel_id/content?from=:from&to=:to&offset=:offset&limit=:limit&content_type=:contnent_type&is_partnership=:is_partnership&account=:account",
    },
    {
      name: "get_company_overview_data",
      url: "/meta/accounts/overview/:date_from/:date_to",
    },
    {
      name: "get_company_content_gallery",
      url: "/meta/accounts/gallery/:date_from/:date_to/:status/:company/:segments/:page",
    },
    {
      name: "get_company_landing_pages",
      url: "/content/business/compare/landing_pages/:date_from/:date_to",
    },
    {
      name: "get_company_landing_pages_detail",
      url: "/content/business/compare/landing_page/detail/:item_id",
    },
    {
      name: "get_gallery_item_details",
      url: "meta/accounts/gallery_item/:id",
    },
    {
      name: "meta_monitoring_accounts",
      url: "/meta/accounts/monitoring_account",
    },
    {
      name: "meta_report_by_platform",
      url: "/meta/reports/platform/:date_from/:date_to/:segment",
    },
    {
      name: "meta_report_by_display_format",
      url: "/meta/reports/display/:date_from/:date_to/:segment",
    },
    {
      name: "meta_report_totals_ads_count",
      url: "/meta/reports/totals/ads_count/:date_from/:date_to/:segment",
    },
    {
      name: "meta_report_totals_reach",
      url: "/meta/reports/totals/reach/:date_from/:date_to/:segment",
    },
    {
      name: "meta_report_totals_cost",
      url: "/meta/reports/totals/cost/:date_from/:date_to/:segment",
    },
    {
      name: "meta_report_totals_reach_gender",
      url: "/meta/reports/totals/reach/gender/:date_from/:date_to/:segment",
    },
    {
      name: "meta_report_totals_reach_country",
      url: "/meta/reports/totals/reach/country/:date_from/:date_to/:segment",
    },
    {
      name: "meta_report_totals_reach_age",
      url: "/meta/reports/totals/reach/age/:date_from/:date_to/:segment",
    },
    {
      name: "save_business_stat_variables",
      url: "/meta/accounts/save_business_cost_constants",
    },
    {
      name: "meta_report_landing_page_visits_per_account",
      url: "/meta/reports/landing_page_visits/:account_id/:date_from/:date_to/:offset/:limit",
    },
    {
      name: "meta_report_total_landing_page_visits",
      url: "/meta/reports/total_landing_page_visits/:date_from/:date_to",
    },
    {
      name: "meta_report_content_count_segments",
      url: "meta/reports/content/segments/count/:date_from/:date_to",
    },
    {
      name: "meta_report_content_reach_segments",
      url: "meta/reports/content/segments/reach/:date_from/:date_to",
    },
    {
      name: "meta_report_histogram_ads_length",
      url: "/meta/reports/histogram/ads_length/:date_from/:date_to",
    },
    {
      name: "meta_get_segments",
      url: "/meta/segments",
    },
  ];
  if (!params) {
    let searchResult = urls.filter((item) => item.name === name);
    if (searchResult.length === 0) {
      throw new Error("Route name not found");
    } else {
      return searchResult[0].url;
    }
  } else {
    let searchResult = urls.filter((item) => item.name === name);
    if (searchResult.length === 0) {
      throw new Error("Route name not found");
    } else {
      let srcUrl = searchResult[0].url;
      let keys = Object.keys(params);
      keys.forEach((item) => {
        srcUrl = srcUrl.replace(":" + item, params[item]);
      });

      return srcUrl;
    }
  }
}
